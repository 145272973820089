import React from "react";
import clsx from "clsx";
import Button from "./Button";
import UniversalLinkButton from "./UniversalLinkButton";
import "./PromoCard.css";

type LinkDef = {
  readonly to: string;
};

type ButtonClickDef = {
  readonly onClick: () => void;
};

type ButtonDef = {
  readonly label: string;
  readonly variant: "primary" | "secondary";
} & (LinkDef | ButtonClickDef);

type PromoCardProps = {
  readonly title: string;
  readonly theme: "red" | "blue" | "green";
  readonly image: string;
  readonly buttons: ReadonlyArray<ButtonDef>;
};

function PromoCard({ title, theme, image, buttons }: PromoCardProps) {
  return (
    <div className={clsx(`promo-card promo-card-theme-${theme}`)}>
      <h4>{title}</h4>
      <img src={image} alt={title} />
      <div className="promo-buttons-container">
        {buttons.map((b) =>
          "to" in b ? (
            <UniversalLinkButton
              key={b.label}
              colour={theme}
              variant={b.variant === "primary" ? "solid" : "outline"}
              size="small"
              to={b.to}
            >
              {b.label}
            </UniversalLinkButton>
          ) : (
            <Button
              key={b.label}
              type="button"
              colour={theme}
              variant={b.variant === "primary" ? "solid" : "outline"}
              size="small"
              onClick={b.onClick}
            >
              {b.label}
            </Button>
          ),
        )}
      </div>
    </div>
  );
}

export default PromoCard;
