import React from "react";
import Seo from "~/components/Seo";
import ParallaxBanner from "~/components/ParallaxBanner";
import PromoCard from "~/components/PromoCard";
import StandardTestimonials from "~/components/StandardTestimonials";
import backgroundImage from "~/images/get-started-bg.png";
import arithmen1Image from "~/images/arithmenillustrations-02.png";
import arithmen2Image from "~/images/arithmenillustrations-04.png";
import arithmen3Image from "~/images/arithmenillustrations-06.png";
import DownloadLinks from "~/components/DownloadLinks";
import TitleBanner from "~/components/TitleBanner";

function GetStartedPage() {
  return (
    <div className="Site-inner Site-inner--index">
      <div className="Content-outer">
        <TitleBanner
          theme="red"
          headline1="ProblemScape is not just gamified learning where students answer multiple choice questions to battle their pets, or to progress in the game."
          headline2="ProblemScape is an adventure game that helps students understand the real-world applications of the math they are learning!"
        />
        <ParallaxBanner id="bannerwithvideo" image={backgroundImage}>
          <div className="promo-cards">
            <PromoCard
              title="Homes"
              theme="red"
              buttons={[
                {
                  label: "Sign Up",
                  variant: "primary",
                  to: "/signup/#parent",
                },
                {
                  label: "Learn More",
                  variant: "secondary",
                  to: "/get-started/homes-learn-more/",
                },
              ]}
              image={arithmen1Image}
            />
            <PromoCard
              title="Classrooms"
              theme="green"
              buttons={[
                {
                  label: "Request a Demo",
                  variant: "primary",
                  to: "/contact/?source=explore-classrooms-request-demo",
                },
                {
                  label: "Interested in Piloting",
                  variant: "primary",
                  to: "/contact/?source=explore-classrooms-interested-in-piloting",
                },
                {
                  label: "Learn More",
                  variant: "secondary",
                  to: "/get-started/classrooms-learn-more/",
                },
              ]}
              image={arithmen2Image}
            />
            <PromoCard
              title="Schools and Districts"
              theme="blue"
              buttons={[
                {
                  label: "Request a Demo",
                  variant: "primary",
                  to: "/contact/?source=explore-schools-request-demo",
                },
                {
                  label: "Interested in Piloting",
                  variant: "primary",
                  to: "/contact/?source=explore-schools-request-demo",
                },
                {
                  label: "Learn More",
                  variant: "secondary",
                  to: "/get-started/schools-learn-more/",
                },
              ]}
              image={arithmen3Image}
            />
          </div>
          <DownloadLinks />
        </ParallaxBanner>
        {/* NOTE: Awards and recognition requested to be removed */}
        <StandardTestimonials />
      </div>
    </div>
  );
}

export function Head() {
  return <Seo title="Get Started" />;
}

export default GetStartedPage;
